import cn from 'classnames'
import { useCallback, useState } from 'react'
import styles from './AccordionList.module.scss'
interface IAccordionList {
  title: string
}

export const AccordionList: React.FC<IAccordionList> = ({ title, children }) => {
  const [expanded, setExpanded] = useState(false)

  const handleOpen = useCallback(() => {
    setExpanded(state => !state)
  }, [])

  return (
    <div className={styles.contentExpandable}>
      <div className={styles.contentHeader} role="button" onClick={handleOpen}>
        {title} <img className={cn({ [styles.arrowUp]: expanded })} src="/chevron.svg" />
      </div>
      <div className={cn(styles.content, { [styles.contentExpanded]: expanded })}>{children}</div>
    </div>
  )
}
