import { FC, memo } from 'react'
import { AccordionList } from '../../../components/AccordionList'
import ButtonStrapi from '../../../components/ButtonStrapi'
import Content from '../../../components/Content'
import styles from './ImportantInformation.module.scss'

interface IImportantInformation {
  __component: string
  id: number
  title?: string
  items: Array<any>
}

const ImportantInformation: FC<IImportantInformation> = ({ items }) => {
  if (items.length < 1) return null

  const lists = [items.slice(0, (items.length + 1) / 2), items.slice((items.length + 1) / 2)]

  const renderAccordionItem = (item, accordionList) => (
    <div key={`${accordionList.id}_${accordionList.title}_${item.id}`} className={styles.cardDownload}>
      <p>{item.description}</p>
      {Boolean(item.button) && (
        <ButtonStrapi
          {...item.button}
          buttonProps={{ size: 'sm', height: '29px', backgroundColor: '#1F81D1' }}
        ></ButtonStrapi>
      )}
    </div>
  )

  const renderAccordion = accordionList => {
    const list = {
      title: accordionList.title,
      accordionItem: [...accordionList.accordionItem],
    }
    return (
      <div key={accordionList.id} className={styles.cards}>
        <AccordionList {...list}>
          {list.accordionItem.map(item => renderAccordionItem(item, accordionList))}
        </AccordionList>
      </div>
    )
  }

  const renderList = accordionGroups => {
    return (
      Boolean(accordionGroups[0]) && (
        <div key={accordionGroups[0]?.id} className={styles.frameColumn2}>
          {accordionGroups.map(renderAccordion)}
        </div>
      )
    )
  }

  return (
    <div className={styles.frame}>
      <Content className={styles.frameColumn}>{lists.map(renderList)}</Content>
    </div>
  )
}

export default memo(ImportantInformation)
