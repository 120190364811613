import { FormControl, Input } from '@chakra-ui/react'
import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import RTM from 'react-input-mask'
import FormError from '../FormError'
import FormLabel from '../FormLabel'

const MASKS = {
  cnpj: '99.999.999/9999-99',
  cpf: '999.999.999-99',
  cel: '(99) 99999-9999',
  cep: '99999-999',
  landline: '(99) 9999-9999',
  monetary: 'R$ 999.999,99',
  date: '99/99/9999',
}

interface MaskedInputProps {
  label: string
  name: string
  required?: boolean
  disabled?: boolean
  placeholder?: string
  mask: 'cnpj' | 'cpf' | 'cel' | 'cep' | 'landline' | 'monetary' | 'date'
  'data-testid'?: string
  defaultValue: string
  onClick?: () => void
}

const MaskedInput: React.FC<MaskedInputProps> = ({ label, name, required, disabled, mask, ...rest }) => {
  const { errors, control } = useFormContext()

  const renderInput = useCallback(
    (props, ref) => (
      <Input
        required={required}
        as={RTM}
        mask={MASKS[mask]}
        isInvalid={errors[name]}
        isDisabled={disabled}
        placeholder={rest.placeholder}
        data-testid={name}
        name={name}
        ref={ref}
        onClick={rest.onClick}
        {...props}
      />
    ),
    [mask, disabled, errors, rest]
  )

  return (
    <FormControl>
      <FormLabel required={required}>{label}</FormLabel>
      <Controller control={control} name={name} required={required} render={renderInput} {...rest} />
      <FormError>{errors[name]?.message}</FormError>
    </FormControl>
  )
}

export default MaskedInput
