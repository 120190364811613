import MoneyInput from '@components/MoneyInput'
import { IItemInputSlider } from '@components/Sections/InvestmentSimulator/InvestimentSimulator.interface'
import SliderInput from '@components/SliderInput'
import { FC } from 'react'

export const propsUnstyled = {
  color: '#3D9DEF',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: { base: 25, lg: 28 },
  disableErrorMessage: true,
}

const RenderSlider: FC<IItemInputSlider & { name: string }> = props => {
  return <SliderInput placeholder="" maxLength={100} {...props} defaultValue={props.min.toString()} label="" />
}
const InputWithSlider: FC<IItemInputSlider & { name: string }> = ({ children, ...props }) => {
  return (
    <>
      {children || (
        <MoneyInput
          label={props.label}
          name={`${props.name}`}
          maxLength={13}
          {...propsUnstyled}
          defaultValue={props.min}
          variant="simulator"
        />
      )}
      <RenderSlider {...props} />
    </>
  )
}

export default InputWithSlider
