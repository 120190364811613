import { Button } from '@chakra-ui/core'
import { useDisclosure } from '@chakra-ui/hooks'
import { ModalSelect } from '@components/Modal/enum'
import { DisclosureContext } from '@components/Modal/provider'
import { IModalList, ModalList } from '@components/ModalList'
import { IStrapiButton } from '@src/interfaces/Home.interface'
import Link from 'next/link'
import React, { FC, useContext } from 'react'
export interface ButtonStrapiProps extends IStrapiButton {
  buttonProps?: any
  handleButton?: () => void
}

const getExternalLinkProps = (type: string) => {
  if (type === 'external') return { target: '_blank', rel: 'noopener noreferrer' }
  if (type === 'file') return { target: '_blank', rel: 'noopener noreferrer', download: true }
  return {}
}

export const reference = (
  type: string,
  url: string | undefined | null,
  page: { key: any } | null,
  file: { url: any }[] | any
) => {
  if (type === 'external' && url) return url
  if (type === 'file' && file) return file[0].url

  return `/${page?.key}`
}
const ButtonModal = (props: any) => {
  const openModal = useContext(DisclosureContext)
  const openHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, modalSelect: ModalSelect) => {
    event.preventDefault()
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    openModal(modalSelect)
  }
  return (
    <RenderButton
      {...props}
      buttonProps={{
        ...props.buttonProps,
        onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => openHandler(e, props?.modalSelect),
      }}
    />
  )
}

const ButtonStrapi: FC<ButtonStrapiProps> = buttonProps => {
  if (['modal'].includes(buttonProps.type)) return <ButtonModal {...buttonProps} />
  if (['internal', 'external', 'file'].includes(buttonProps.type)) return <ButtonStrapiFiles {...buttonProps} />
  if (['modal_list'].includes(buttonProps.type) && buttonProps.modalList) return <ButtonStrapiList {...buttonProps} />
  if (buttonProps.type == null) {
    return (
      <div onClick={buttonProps.handleButton}>
        <ButtonNoRedirect {...buttonProps} />
      </div>
    )
  }
  return null
}

const RenderButton: FC<ButtonStrapiProps & { href?: any }> = React.forwardRef(
  ({ type, children, label, href, buttonProps, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        href={href}
        as="a"
        {...getExternalLinkProps(type)}
        backgroundColor="#3D9DEF"
        variantColor="blue"
        height="57px"
        {...buttonProps}
        {...props}
        isTruncated
      >
        {children || <h1>{label}</h1>}
      </Button>
    )
  }
)
RenderButton.displayName = 'ButtonStrapiList'

const ButtonStrapiList: FC<ButtonStrapiProps> = ({ modalList: list, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const modalList = list as IModalList
  return (
    <>
      <RenderButton {...props} buttonProps={{ onClick: onOpen, ...props.buttonProps }} />
      <ModalList {...{ isOpen, onClose, modalList }} />
    </>
  )
}

const ButtonStrapiFiles: FC<ButtonStrapiProps> = ({ type, url, page, file, ...props }) => {
  return (
    <Link href={reference(type, url, page, file)} passHref>
      <RenderButton {...props} {...{ type, url, page, file }} />
    </Link>
  )
}

const ButtonNoRedirect: FC<ButtonStrapiProps> = ({ type, url, page, file, ...props }) => {
  return <RenderButton {...props} {...{ type, url, page, file }} />
}

export default ButtonStrapi
