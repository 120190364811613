import { colorStrapi } from '@src/utils/strapi'
import { FC } from 'react'
import { IImageFrame } from '../../interfaces/Home.interface'
import styles from '../ImageFrame/ImageFrame.module.scss'

export interface IImageProps {
  backgroundColor?: string
  fillBackground?: boolean
  image?: any
  id?: number
}

const ImagenPresentation: FC<IImageProps> = ({ image, fillBackground, backgroundColor }) => {
  const color = colorStrapi(backgroundColor, '#9df3ce')
  const styleProp =
    fillBackground || backgroundColor
      ? {
          backgroundColor: fillBackground ? color : '',
          border: `1px solid ${color}`,
        }
      : undefined

  return <img src={image.url} style={styleProp} />
}

export const ImageFrame: FC<IImageFrame> = ({
  flipHorizontal,
  flipVertical,
  mainImage,
  sideImage1,
  sideImage2,
  sideImage3,
  fillBackground,
  backgroundColor,
}) => {
  const isFrameConstainsSideImage3 = Boolean(sideImage3)
  const onlyMainImage = Boolean(!sideImage1 && !sideImage2 && !sideImage3)

  const RenderMainImage: FC<IImageProps> = props => (
    <div className={styles.mainImage}>
      <ImagenPresentation {...props} />
    </div>
  )
  const RenderSideImage1: FC<IImageProps> = props => (
    <div className={styles.sideImage1}>
      <ImagenPresentation {...props} />
    </div>
  )
  const RenderSideImage2: FC<IImageProps> = props => (
    <div className={styles.sideImage2}>
      <ImagenPresentation {...props} />
    </div>
  )
  const RenderSideImage3: FC<IImageProps> = props =>
    isFrameConstainsSideImage3 ? (
      <div className={styles.sideImage3}>
        <ImagenPresentation {...props} />
      </div>
    ) : null

  return onlyMainImage ? (
    <div className={styles.frameOneImage}>
      <RenderMainImage image={mainImage} />
    </div>
  ) : (
    <div className={!flipHorizontal ? styles.framePrincipal : styles.framePrincipalReverse}>
      <div className={!flipVertical ? styles.frameImage1 : styles.frameImage1Reverse}>
        <RenderMainImage image={mainImage} />
      </div>
      <div className={!flipVertical ? styles.frameImage2 : styles.frameImage2Reverse}>
        <RenderSideImage1 image={sideImage1} />
        {isFrameConstainsSideImage3 ? (
          <div className={styles.frameImage4}>
            <RenderSideImage2 image={sideImage2} />
            <RenderSideImage3 image={sideImage3} fillBackground={fillBackground} backgroundColor={backgroundColor} />
          </div>
        ) : (
          <div className={styles.frameImage3}>
            <RenderSideImage2 image={sideImage2} fillBackground={fillBackground} backgroundColor={backgroundColor} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ImageFrame
