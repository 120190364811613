import Markdown from '@components/Markdown'
import cn from 'classnames'
import { useCallback, useState } from 'react'
import { AccordionList } from '../../../components/AccordionList'
import Content from '../../../components/Content'
import styles from './FAQ.module.scss'

const Item = ({ item, children, isActive, onClick }) => {
  const handleClick = useCallback(() => {
    onClick(item)
  }, [])

  return (
    <div onClick={handleClick} className={cn(styles.menuItem, { [styles.active]: isActive })}>
      {children}
    </div>
  )
}

const FAQ = ({ items }) => {
  const [selectedItem, setSelectedItem] = useState(items[0])

  const renderMenuItems = item => {
    const isActive = item.id === selectedItem.id
    return (
      <Item key={item.id} onClick={setSelectedItem} item={item} isActive={isActive}>
        <div className={styles.dot} /> {item.title}
      </Item>
    )
  }

  return (
    <div className={styles.container}>
      <Content className={styles.content}>
        <div className={styles.menu}>{items.map(renderMenuItems)}</div>
        <div className={styles.questionsContainer}>
          <span>{selectedItem.title}</span>
          <div className={styles.questions}>
            {selectedItem.questions.map(question => {
              const accordionList = {
                title: question.question,
                items: [question.answer],
                ...question,
              }
              return (
                <AccordionList key={question.id} {...accordionList}>
                  <Markdown>{question.answer}</Markdown>
                </AccordionList>
              )
            })}
          </div>
          <div className={styles.questionsFooter}>
            <h3>Não encontrou o que procurava?</h3>
            <a href="/ajuda">
              Registre sua dúvida na central de atendimento <img src="/arrow-right.svg" />
            </a>
          </div>
        </div>
      </Content>
    </div>
  )
}

export default FAQ
