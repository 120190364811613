import Content from '@components/Content'
import PresentationButton from '@components/PresentationButton'
import Image from 'next/image'
import { memo } from 'react'
import useWindowSize from '../../../hooks/useWindowSize'
import styles from './AboutHome.module.scss'

const AboutHome = ({ name, title, description, image, logo, button }) => {
  const { isMobile } = useWindowSize()
  return (
    <div>
      <Content className={styles.content}>
        {isMobile ? (
          <>
            <div className={styles.image}>
              <Image
                layout="responsive"
                objectFit="cover"
                src={image.url}
                quality={90}
                width={image.width}
                height={image.height}
              />
            </div>
            <div className={styles.logo}>
              <Image src={logo.url} quality={90} width={260} height={76} />
            </div>
            <div className={styles.left}>
              <div className={styles.heading}>{name}</div>
              <div className={styles.title}>{title}</div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.left}>
              <div className={styles.heading}>{name}</div>
              <div className={styles.title}>{title}</div>
              <Image src={logo.url} quality={90} width={260} height={76} />
            </div>
            <div className={styles.image}>
              <Image layout="fill" src={image.url} quality={90} />
            </div>
          </>
        )}
        <div className={styles.right}>
          <p className={styles.description}>{description}</p>
          <PresentationButton isFullWidth {...button} />
        </div>
      </Content>
    </div>
  )
}

export default memo(AboutHome)
