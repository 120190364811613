import { FormControl, Input } from '@chakra-ui/react'
import React, { useCallback, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import FormError from '../FormError'
import FormLabel from '../FormLabel'

interface PhoneInputProps {
  label: string
  name: string
  required?: boolean
  disabled?: boolean
  placeholder?: string
  defaultValue: string
  maxLength: number
}

const PhoneInput: React.FC<PhoneInputProps> = ({ label, name, required, disabled, maxLength, ...rest }) => {
  const { errors, control, watch, setValue } = useFormContext()

  const inputValue = watch(name)

  useEffect(() => {
    if (inputValue) {
      let newValue = inputValue.replace(/\D/g, '') // Remove tudo o que não é dígito
      newValue = newValue.replace(/^(\d{2})(\d)/g, '($1) $2') // Coloca parênteses em volta dos dois primeiros dígitos
      newValue = newValue.replace(/(\d)(\d{4})$/, '$1-$2') // Coloca hífen entre o quarto e o quinto dígitos
      setValue(name, newValue)
    } else {
      setValue(name, rest.defaultValue)
    }
  }, [inputValue, setValue])

  const renderInput = useCallback(
    (props, ref) => (
      <Input
        required={required}
        isInvalid={errors[name]}
        isDisabled={disabled}
        placeholder={rest.placeholder}
        data-testid={name}
        name={name}
        ref={ref}
        maxLength={maxLength}
        {...props}
      />
    ),
    [disabled, errors, rest]
  )

  return (
    <FormControl>
      <FormLabel required={required}>{label}</FormLabel>
      <Controller control={control} name={name} required={required} render={renderInput} {...rest} />
      <FormError>{errors[name]?.message}</FormError>
    </FormControl>
  )
}

export default PhoneInput
