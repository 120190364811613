import Content from '@components/Content'
import { GenerateLinkIcon, ILinkProps } from '@components/GenerateLink'
import { FC, memo } from 'react'
import styles from './CondictionInfo.module.scss'

interface ICondiction {
  __component: string
  id: number
  title: string
  subTitle: string
  link: ILinkProps
}

const CondictionInfo: FC<ICondiction> = ({ title, subTitle, link }: ICondiction) => {
  return (
    <Content className={styles.content}>
      <div className={styles.condition}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subTitle}>{subTitle}</div>
      </div>

      <div className={styles.link}>
        <GenerateLinkIcon {...link} />
      </div>
    </Content>
  )
}

export default memo(CondictionInfo)
