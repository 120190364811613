import { Spinner } from '@chakra-ui/core'
import Footer from '@components/Footer/Footer'
import FraudConsent from '@components/FraudConsent'
import { EPosition } from '@components/FraudConsent/FraudConsent.enum'
import Header from '@components/Header'
import AboutBillsExchange from '@components/Sections/AboutBillsExchange'
import AboutHome from '@components/Sections/AboutHome'
import AboutWithIconOverImage from '@components/Sections/AboutWithIconOverImage'
import CardsWithIcon from '@components/Sections/CardsWithIcon'
import CondictionInfo from '@components/Sections/CondictionInfo'
import ContactUs from '@components/Sections/ContactUs'
import ContentMarkdown from '@components/Sections/Content'
import CorrespondentBank from '@components/Sections/CorrespondentBank'
import Cover from '@components/Sections/Cover'
import CreditOpeningForm from '@components/Sections/CreditOpeningForm'
import DescriptionWithIcon from '@components/Sections/DescriptionWithIcon'
import FAQ from '@components/Sections/FAQ'
import FeesAndDeadline from '@components/Sections/FeesAndDeadline'
import ImportantInformation from '@components/Sections/ImportantInformation'
import InvestimentSimulator from '@components/Sections/InvestmentSimulator'
import LoanSimulator from '@components/Sections/LoanSimulator'
import ModalityList from '@components/Sections/ModalityList'
import NumbersPresentation from '@components/Sections/NumbersPresentation'
import PagePresentation from '@components/Sections/PagePresentation'
import PagePresentationWithTabs from '@components/Sections/PagePresentationWithTabs'
import Slider from '@components/Sections/Slider'
import { Separator } from '@components/Separator'
import * as Sentry from '@sentry/nextjs'
import {
  ICookiesConsent,
  IFooter,
  IFraudConsent,
  IGenericComponent,
  IMenuData,
  IPageData,
  IPrivacyPolicyProps,
  OpenYourAccountAlertProps,
} from 'interfaces/Home.interface'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { api } from '../service/api'
import styles from '../styles/Home.module.css'

interface IHome {
  menuData: IMenuData
  pageData: IPageData
  footerData: IFooter
  cookiesData: ICookiesConsent
  privacyPolicyData: IPrivacyPolicyProps
  fraudData: IFraudConsent
  privacyPolicyPageData: IPrivacyPolicyProps
  openYourAccountAlert: OpenYourAccountAlertProps
}

const sectionComponents = {
  'sections.about-home': AboutHome,
  'sections.cover': Cover,
  'sections.page-presentation': PagePresentation,
  'sections.faq': FAQ,
  'sections.cards-with-icon': CardsWithIcon,
  'sections.important-info': ImportantInformation,
  'sections.condition-frame': CondictionInfo,
  'sections.separator': Separator,
  'sections.modality-list': ModalityList,
  'sections.description-with-icon': DescriptionWithIcon,
  'sections.correspondent-bank': CorrespondentBank,
  'sections.credit-opening-form': CreditOpeningForm,
  'sections.about-bills-exchange': AboutBillsExchange,
  'sections.about-with-icon-over-image': AboutWithIconOverImage,
  'sections.loan-simulator': LoanSimulator,
  'sections.fees-and-deadlines': FeesAndDeadline,
  'sections.page-presentation-with-tabs': PagePresentationWithTabs,
  'sections.investiment-simulator': InvestimentSimulator,
  'sections.number-presentation': NumbersPresentation,
  'sections.contact-us': ContactUs,
  'sections.slider': Slider,
  'sections.content': ContentMarkdown,
}

const PRIVACY_DATE_STORAGE = 'privacyPolicyDate'

const Home: React.FC<IHome> = ({
  menuData,
  pageData,
  footerData,
  cookiesData,
  fraudData,
  privacyPolicyData,
  privacyPolicyPageData,
  openYourAccountAlert,
}) => {
  const router = useRouter()
  const [showPositionFraud, setPositionAlertFraud] = useState(EPosition.HIDE)
  if (typeof window !== 'undefined') {
    window.onload = function () {
      if (
        showPositionFraud === EPosition.HIDE &&
        fraudData &&
        fraudData.message &&
        !(sessionStorage.getItem('showAlertFraud') === 'false')
      ) {
        const cookModalOpen = document.getElementsByClassName('cookie-notice').length > 0
        setPositionAlertFraud(cookModalOpen ? EPosition.HEADER : EPosition.FOOTER)
      }
    }
  }

  if (router.isFallback) {
    return (
      <div className={styles.loadingContainer}>
        <Spinner color="blue" size="xl" />
      </div>
    )
  }

  const { title, description, items, key } = pageData

  const renderComponent = (item: IGenericComponent) => {
    const Comp = sectionComponents[item.__component]
    return Comp ? <Comp key={`${item.__component}-${item.id}`} {...item} /> : null
  }

  const savaDatePrivacyPolicy = () => {
    if (privacyPolicyPageData && privacyPolicyPageData.updated_at) {
      localStorage.setItem(PRIVACY_DATE_STORAGE, privacyPolicyPageData.updated_at)
    }
  }

  if (typeof window !== 'undefined' && privacyPolicyData && privacyPolicyPageData) {
    privacyPolicyData.show = false
    if (key === 'politica-de-privacidade') {
      savaDatePrivacyPolicy()
    } else {
      const privacyPolicyDateStorage = localStorage.getItem(PRIVACY_DATE_STORAGE)
      if (privacyPolicyDateStorage && privacyPolicyDateStorage.length > 0) {
        if (privacyPolicyDateStorage !== privacyPolicyPageData.updated_at) {
          privacyPolicyData.show = true
        }
      }
    }
  }

  const closeAlertFraud = () => {
    setPositionAlertFraud(EPosition.HIDE)
    sessionStorage.setItem('showAlertFraud', 'false')
  }

  return (
    <div className={styles.container}>
      <span style={{ display: 'none' }}>GIT_HASH: {process.env.NEXT_PUBLIC_GIT_HASH} </span>

      <Head>
        <title>{title} | GazinBank </title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="description" content={description || ''} />
        {process.env.NODE_ENV !== 'production' ? <meta name="robots" content="noindex,nofollow" /> : null}
      </Head>

      <Header {...menuData} openYourAccountAlert={openYourAccountAlert} />
      <FraudConsent {...fraudData} positionAlertFraud={showPositionFraud} onClose={closeAlertFraud} />
      {items.map(renderComponent)}
      <Footer
        footerList={footerData}
        cookiesData={cookiesData}
        privacyPolicyData={privacyPolicyData}
        savaDatePrivacyPolicy={savaDatePrivacyPolicy}
      />
    </div>
  )
}

export default Home

export async function getStaticPaths() {
  const pages = await api
    .get(`/pages`)
    .then(response => response.data)
    .catch(ex => {
      Sentry.captureException(ex)
      return []
    })

  const paths = pages.map(page => ({ params: { slug: String(page.key).split('/') } }))

  return {
    paths: [...paths, { params: { slug: [] } }],
    fallback: true,
  }
}

export async function getStaticProps(context) {
  const { slug } = context.params
  const catchError = error => {
    Sentry.captureException(error)
    return Promise.resolve({ data: null })
  }
  try {
    const [
      { data: cookiesData },
      { data: menuData },
      { data: footerData },
      { data: fraudData },
      { data: privacyPolicyData },
      { data: privacyPolicyPageData },
      { data: openYourAccountAlert },
    ] = await Promise.all([
      api.get('/cookies').catch(catchError),
      api.get('/menu').catch(catchError),
      api.get('/footer').catch(catchError),
      api.get('/fraud').catch(catchError),
      api.get('/privacy-policy').catch(catchError),
      api.get('/pages/key/politica-de-privacidade').catch(catchError),
      api.get('/open-your-account-alert').catch(catchError),
    ])
    const { data: pageData } = await api.get(slug ? `/pages/key/${slug}` : '/pages/key/home')

    return {
      props: {
        menuData,
        pageData,
        cookiesData,
        footerData,
        privacyPolicyData,
        fraudData,
        privacyPolicyPageData,
        openYourAccountAlert,
      },
      revalidate: 180,
    }
  } catch (ex) {
    Sentry.captureException(ex)
    return {
      notFound: true,
      revalidate: 10,
    }
  }
}
