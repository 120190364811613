import { GenerateLinkIcon, ILinkProps } from '@components/GenerateLink'
import Image from 'next/image'
import { FC } from 'react'
import styles from './TextWithIcon.module.scss'

export interface ITextWithIcon {
  id: number
  text: string
  icon: any
  link: ILinkProps
}

export const TextWithIcon: FC<ITextWithIcon> = ({ text, icon, link }) => {
  return (
    <div className={link ? styles.infoTextContent + ' ' + styles.infoTextContentWithLink : styles.infoTextContent}>
      <div className={styles.iconContent}>{icon && icon.url && <Image src={icon.url} width={35} height={35} />}</div>
      <div>
        <div className={link ? styles.infoTextLink : styles.infoText}>{text}</div>
        <div className={styles.space16px}></div>
        {link && <div className={styles.link}>{Boolean(link) && <GenerateLinkIcon {...link}></GenerateLinkIcon>}</div>}
      </div>
    </div>
  )
}
