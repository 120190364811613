import Content from '@components/Content'
import { GenerateLink } from '@components/GenerateLink'
import Markdown from '@components/Markdown'
import { IFraudConsent } from '@src/interfaces/Home.interface'
import React, { useEffect, useState } from 'react'
import { EPosition } from './FraudConsent.enum'
import styles from './FraudConsent.module.scss'

const FraudConsent: React.FC<IFraudConsent> = ({ message, link, positionAlertFraud, onClose }) => {
  const [bottomChat, setBottomChat] = useState<number | undefined>(undefined)

  useEffect(
    /* istanbul ignore next */
    () => {
      const fraudModal = document.getElementById('fraud-modal')
      const cssChat = document.getElementById('blip-chat-open-iframe')
      if (positionAlertFraud === EPosition.FOOTER) {
        if (fraudModal && fraudModal.offsetHeight > 0) {
          if (cssChat) {
            if (!bottomChat) {
              const heightScreen = window.innerHeight
              const heightChat = cssChat.offsetHeight
              const topChat = cssChat.offsetTop
              setBottomChat(heightScreen - heightChat - topChat)
            }
            cssChat.style.bottom = `${fraudModal.offsetHeight + 5}px`
          }
        }
      } else {
        if (cssChat && bottomChat) {
          cssChat.style.bottom = `${bottomChat}px`
        }
      }
    },
    [positionAlertFraud]
  )

  return (
    <div style={positionAlertFraud === EPosition.HIDE ? { display: 'none' } : undefined}>
      <div
        id="fraud-modal"
        className={positionAlertFraud === EPosition.HEADER ? styles.fraudHeader : styles.fraudFooter}
      >
        <Content className={styles.contentCuston}>
          <div className={styles.textContent}>
            <Markdown>{message}</Markdown> {link && <GenerateLink {...link}>{link.label}</GenerateLink>}
          </div>
          <div className={styles.buttonContent}>
            <img src="/close-white.svg" onClick={() => onClose()} />
          </div>
        </Content>
      </div>
    </div>
  )
}

export default FraudConsent
