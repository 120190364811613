import { Spinner } from '@chakra-ui/core'
import cn from 'classnames'
import styles from './LoadingOverlay.module.scss'

interface IProps {
  loading: boolean
}

const LoadingOverlay: React.FC<IProps> = ({ children, loading }) => {
  return (
    <div className={styles.loadingContainer}>
      {children}
      <div className={cn(styles.spinner, { [styles.spinnerLoading]: loading })}>
        <Spinner />
      </div>
    </div>
  )
}

export default LoadingOverlay
