import { getExternalLinkProps, ILinkProps, ref } from '@components/GenerateLink'
import Image from 'next/image'
import Link from 'next/link'
import { FC } from 'react'
import styles from './CardWithIconLink.module.scss'

export interface ICardWithIconLink {
  id: number
  icon: any
  link: ILinkProps
}

export const CardWithIconLink: FC<ICardWithIconLink> = ({ icon, link }) => {
  const { type, url, page, file, external } = link
  const hRef = ref(type, url, page, file, external)

  return (
    <Link href={hRef} passHref>
      <a {...getExternalLinkProps(type, external)} className={styles.card}>
        <div className={styles.cardIcon}>{Boolean(icon) && <Image src={icon.url} width={45} height={45} />}</div>
        <div className={styles.linkLabel}>{link.label}</div>
      </a>
    </Link>
  )
}
