import { useEffect } from 'react'

const BlipChat = () => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof window === 'undefined') return
    const script = document.createElement('script')
    script.async = true
    script.innerText = `(function () {
      new BlipChat()
        .withAppKey('Z2F6aW5yb3V0ZXJobWc6YTg3YmQ2YzQtYjg3ZS00ZTljLThjZjAtMjNiZjAzN2YyMmNl')
        .withButton({ color: '#3D9DEF', icon: '' })
        .withCustomCommonUrl('https://gazin.chat.blip.ai/')
        .build()
    })()`

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return null
}

export default BlipChat
