import cn from 'classnames'
import styles from './Content.module.scss'

interface IContent {
  className?: string
}

const Content: React.FC<IContent> = ({ children, className }) => {
  return <div className={cn(styles.content, className)}>{children}</div>
}

export default Content
