import { Alert as ChackraAlert } from '@chakra-ui/core'
import { FC } from 'react'
import styles from './Alert.module.scss'

export interface IAlert {
  status?: 'info' | 'warning' | 'success' | 'error'
  message?: string
}

const Alert: FC<IAlert> = ({ status, message }) => {
  return (
    <ChackraAlert status={status} className={styles.alert}>
      {message}
    </ChackraAlert>
  )
}

export default Alert
