import CardOpenAccount from '@components/CardOpenAccount'
import { getExternalLinkProps, ref } from '@components/GenerateLink'
import { ModalSelect } from '@components/Modal/enum'
import { DisclosureContext } from '@components/Modal/provider'
import { IMenuData, IPage } from '@src/interfaces/Home.interface'
import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { memo, useCallback, useContext, useEffect, useState } from 'react'
import useWindowSize from '../../hooks/useWindowSize'
import ButtonItemMenu from './ButtonItemMenu'
import styles from './Header.module.scss'

const DRAWER_ANIMATION_DELAY = 300

const Header: React.FC<IMenuData> = ({ logo, items: menuItems, openYourAccountAlert }) => {
  const [menuOpened, setMenuOpened] = useState(false)
  const [sortedMenu, setSortedMenu] = useState(false)
  const { query } = useRouter()
  const size = useWindowSize()

  const [menuBackgroundVisibe, setMenuBackgroundVisible] = useState(false)

  // WEB
  const renderMenuItem = ({
    __component,
    id,
    external,
    url,
    label,
    page,
    renderButton,
    modalList,
    ...props
  }: IPage & { renderButton?: boolean } & { modalList?: ModalSelect }) => {
    const currentPageKey = query.slug?.length ? query.slug[0] : null
    const hRef = ref('', url, page, null, external)

    const openModal = useContext(DisclosureContext)

    const RenderItemHeader = useCallback(
      ({ modalList, label }) => {
        return (
          <a onClick={() => openModal(modalList)}>
            <div
              className={
                renderButton
                  ? styles.copyStyleButtonMenu
                  : cn(styles.item, { [styles.itemActive]: currentPageKey === page?.key })
              }
            >
              {label}
            </div>
          </a>
        )
      },
      [modalList, label]
    )

    const Render = useCallback(() => {
      if (label && modalList) {
        if (renderButton) {
          return <ButtonItemMenu {...props} label={label} callbackFn={() => openModal(modalList)} />
        } else {
          return <RenderItemHeader modalList={modalList} label={label} />
        }
      }

      return (
        <Link href={hRef} key={`${__component}-${id}`} passHref>
          <a {...getExternalLinkProps('', external)}>
            <div
              className={
                renderButton
                  ? styles.copyStyleButtonMenu
                  : cn(styles.item, { [styles.itemActive]: currentPageKey === page?.key })
              }
            >
              {label}
            </div>
          </a>
        </Link>
      )
    }, [renderButton, label, modalList, currentPageKey])
    return <Render />
  }

  const renderDropdownMenuItem = (item: IPage) => {
    const currentPageKey = query.slug?.length ? query.slug[0] : null
    const test = item.items?.filter(currItem => currItem.page?.key === currentPageKey)

    return (
      <div key={`${item.__component}-${item.id}`} className={styles.dropdown}>
        <div className={cn(styles.item, { [styles.itemActive]: test?.length })}>
          {item.label}
          <img src="/chevron.svg" />
        </div>
        <div className={styles.dropdownContent}>
          <div className={styles.arrow} />
          {item.items?.map(renderMenuItem)}
        </div>
      </div>
    )
  }
  const renderItem = (item: IPage) => {
    if (item.__component === 'menu.dropdown') {
      return renderDropdownMenuItem(item)
    }
    return renderMenuItem({ ...item })
  }
  // MOBILE
  const renderMenuItemMobile = ({ __component, id, external, url, page, label, ...props }: IPage) => {
    const currentPageKey = query.slug?.length ? query.slug[0] : null
    const type = 'internal'
    const file = null

    const RenderCallback = useCallback(() => {
      if (props.type === 'modal')
        return (
          <>
            <div className={styles.divider} />
            <CardOpenAccount {...openYourAccountAlert} />
          </>
        )
      const hRef = ref(type, url, page, file, external)
      return (
        <Link key={`${__component}-${id}`} href={hRef} passHref>
          <a {...getExternalLinkProps('', external)}>
            <div className={cn(styles.itemMobile, { [styles.itemMobileActive]: currentPageKey === page?.key })}>
              {label}
            </div>
          </a>
        </Link>
      )
    }, [label])

    return <RenderCallback />
  }

  const renderDropdownMenuItemMobile = ({ __component, id, label, items }: IPage, index: number) => {
    const previesElement = menuItems[index - 1]

    return (
      <div key={`${__component}-${id}`}>
        {previesElement?.__component === 'menu.item' ? <div className={styles.divider} /> : null}
        <p className={styles.dropdownLabel}>{label}</p>
        <div className={styles.dropdownItemsMobile}>{items?.map(renderMenuItemMobile)}</div>
        <div className={styles.divider} />
      </div>
    )
  }

  const renderMobileItem = (item: IPage, index: number) => {
    if (item.__component === 'menu.dropdown') {
      return renderDropdownMenuItemMobile(item, index)
    }

    return renderMenuItemMobile({ ...item })
  }

  const handleOpenMenu = useCallback(() => {
    setMenuOpened(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setMenuOpened(false)
  }, [])

  useEffect(() => {
    if ((size.isMobile || menuOpened) && !sortedMenu) {
      menuItems.sort((itemA, itemB) => {
        if (itemA.__component !== 'menu.dropdown') {
          return 1
        }
        if (itemA.__component === itemB.__component) {
          return 0
        }
        return -1
      })
      menuItems.sort((itemA, itemB) => {
        if (itemB.type === 'modal') {
          return -1
        }
        return 0
      })

      setSortedMenu(true)
    }
  }, [size.width, menuOpened])

  useEffect(() => {
    if (menuOpened) {
      setMenuBackgroundVisible(true)
    } else {
      setTimeout(() => {
        setMenuBackgroundVisible(false)
      }, DRAWER_ANIMATION_DELAY)
    }
  }, [menuOpened])

  useEffect(() => {
    const windowWidth = size.width || 0
    if (windowWidth > 1200 && menuOpened) {
      setMenuOpened(false)
    }
  }, [size.width, menuOpened])

  useEffect(() => {
    setMenuOpened(false)
  }, [query.slug])

  return (
    <div className={styles.header}>
      <div className={styles.content}>
        <div className={styles.menuItems}>
          <Link href="/">
            <a style={{ ['margin-top' as any]: '3px' }}>
              <Image src={logo?.url} width={166} height={40} quality={90} />
            </a>
          </Link>
          {menuItems.map(renderItem)}
        </div>
      </div>
      <div className={styles.contentMobile}>
        <Link href="/" passHref>
          <a>
            <Image src={logo?.url} width={164} height={43} quality={90} />
          </a>
        </Link>

        <img className={styles.menuOpener} data-testid="menu-open" src="/menu.svg" onClick={handleOpenMenu} />
      </div>
      <div
        className={cn({
          [styles.drawerBackground]: menuOpened,
          [styles.drawerBackgroundVisible]: menuBackgroundVisibe,
        })}
      >
        <div className={cn(styles.drawer, { [styles.drawerOpened]: menuOpened })}>
          <div className={styles.drawerContent}>
            <Image src={logo?.url} width={161} height={43} quality={90} />
            <img className={styles.menuOpener} data-testid="menu-close" src="/close.svg" onClick={handleCloseMenu} />
          </div>
          <div className={styles.menuItemsMobile}>{menuItems.map(renderMobileItem)}</div>
        </div>
      </div>
    </div>
  )
}

export default memo(Header)
