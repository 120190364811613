import React, { FC } from 'react'
import styles from './CardFrame.module.scss'

export interface iCard {
  title?: string
  description?: string
  subtitle?: string
}

const RenderWithExists = ({ className, children }) => {
  return children ? <div className={className}>{children}</div> : null
}

export const CardFrameButton: FC<iCard> = card => {
  const renderFrame: FC<iCard> = ({ children, title, description, subtitle }) => {
    return (
      <div className={styles.frame}>
        <div>
          <RenderWithExists className={styles.title}>{title}</RenderWithExists>
          <RenderWithExists className={styles.subtitle}>{subtitle}</RenderWithExists>
        </div>
        <RenderWithExists className={styles.description}>{description}</RenderWithExists>
        <div>
          <RenderWithExists className="">{children}</RenderWithExists>
        </div>
      </div>
    )
  }
  return renderFrame(card)
}

export const CardFrameCheck: FC<iCard> = card => {
  const renderFrame: FC<iCard> = ({ children, title, description, subtitle }) => {
    return (
      <div className={styles.frame}>
        <div>
          <RenderWithExists className={styles.title}>{title}</RenderWithExists>
          <RenderWithExists className={styles.subtitle}>{subtitle}</RenderWithExists>
        </div>
        <RenderWithExists className="">{children}</RenderWithExists>
        <div>
          <RenderWithExists className={styles.description}>{description}</RenderWithExists>
        </div>
      </div>
    )
  }
  return renderFrame(card)
}
