import { Flex } from '@chakra-ui/core'
import { FormControl, InputProps, Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/react'
import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import FormError from '../FormError'
import FormLabel from '../FormLabel'

interface SliderInputProps {
  label: string
  name: string
  required?: boolean
  disabled?: boolean
  placeholder?: string
  maxLength: number
  value?: string
  variant?: string
  className?: string
  color?: string
  min: number
  step?: number
  max: number
}

const styleThumb = {
  // style fix
  style: {
    boxShadow: 'none !important',
    color: '#3D9DEF',
    backgroundColor: '#3D9DEF',
  },
}

const SliderInput: React.FC<SliderInputProps & InputProps> = ({ label, name, required, ...rest }) => {
  const { errors, control, watch } = useFormContext()

  const value = watch(name, rest.defaultValue)

  const renderInput = useCallback(
    props => {
      return (
        <Slider
          label="slider"
          aria-label="slider"
          name={name}
          colorScheme="blue"
          {...rest}
          {...props}
          value={value || 0}
          focusThumbOnChange={false}
          h="28px"
        >
          <SliderTrack bg="#E2F0FD">
            <SliderFilledTrack bg="#3D9DEF" />
          </SliderTrack>
          <SliderThumb {...styleThumb} boxSize={6} border="1px solid #FFFF" />
        </Slider>
      )
    },
    [rest, name, value]
  )

  return (
    <FormControl>
      <FormLabel required={required}>{label}</FormLabel>
      <Flex w="100%" direction="column">
        <Controller control={control} name={name} required={required} render={renderInput} />
        <FormError>{errors[name]?.message}</FormError>
      </Flex>
    </FormControl>
  )
}

export default SliderInput
