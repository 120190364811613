import { Button } from '@chakra-ui/core'
import Form from '@components/Form'
import MaskedInput from '@components/MaskedInput'
import MoneyInput from '@components/MoneyInput'
import PhoneInput from '@components/PhoneInput'
import TextAreaInput from '@components/TextAreaInput'
import TextInput from '@components/TextInput'
import { yupResolver } from '@hookform/resolvers/yup'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { validateCPF, validateMoney, validatePhoneOrCelphone, yupValidateCNPJ } from '../../utils/validations'
import { IStrapiInput } from './StrapiForm.interface'
import styles from './StrapiForm.module.scss'

const inputComponents = {
  text: TextInput,
  textarea: TextAreaInput,
  money: MoneyInput,
  phone: PhoneInput,
  email: TextInput,
  // eslint-disable-next-line react/display-name
  cnpj: props => <MaskedInput mask="cnpj" {...props} />,
  // eslint-disable-next-line react/display-name
  cpf: props => <MaskedInput mask="cpf" {...props} />,
}

const inputValidations = {
  text: yup.string(),
  textarea: yup.string(),
  cpf: validateCPF,
  cnpj: yupValidateCNPJ,
  email: yup.string().email('Insira um e-mail válido'),
  phone: validatePhoneOrCelphone,
  money: validateMoney,
}

interface IStrapi {
  inputs: Array<IStrapiInput>
  onSubmit(formData: any, form: any): Promise<void>
  submitText: string
  loading: boolean
  buttonFullWidth?: boolean
}

const StrapiForm: React.FC<IStrapi> = ({ onSubmit, submitText, inputs, loading, buttonFullWidth }) => {
  const schema = useRef(
    yup.object().shape(
      inputs.reduce((acc, curr) => {
        const newAcc = { ...acc }
        const validator = inputValidations[curr.type]
        if (validator) {
          newAcc[curr.name] = curr.required && validator?.required ? validator.required('Campo obrigatório') : validator
        }

        return newAcc
      }, {})
    )
  )

  const form = useForm({ resolver: yupResolver(schema.current), mode: 'onBlur' })

  const renderInput = ({ id, type, name, full_width, max_length, ...rest }) => {
    const Comp = inputComponents[type]

    return (
      <div key={id} className={full_width ? styles.inputFull : styles.inputHalf}>
        <Comp name={name} maxLength={max_length} defaultValue="" {...rest} />
      </div>
    )
  }

  return (
    <Form form={form} onSubmit={form.handleSubmit(formData => onSubmit(formData, form))}>
      <div className={styles.inputsContainer}>{inputs.map(renderInput)}</div>
      <Button
        isLoading={loading}
        className={styles.formButton}
        width={buttonFullWidth ? '100%' : '48%'}
        size="lg"
        backgroundColor="#3D9DEF"
        color="#FFF"
        variantColor="blue"
        type="submit"
      >
        {submitText}
      </Button>
    </Form>
  )
}

export default StrapiForm
