import { Button } from '@chakra-ui/core'
import { getExternalLinkProps } from '@components/GenerateLink'
import { memo } from 'react'
import styles from './ButtonItemMenu.module.scss'

const ButtonItemMenu = item => {
  return (
    <Button
      className={styles.buttonItemMenu}
      bg="#1897EE"
      width="181px"
      height="44px"
      fontSize="16px"
      padding="12px 32px"
      fontWeight="600"
      _hover={{ bg: '#1f81d1' }}
      as="a"
      {...getExternalLinkProps(item.type)}
      onClick={item?.callbackFn}
    >
      {item.label}
    </Button>
  )
}

export default memo(ButtonItemMenu)
