import { Button } from '@chakra-ui/core'
import Content from '@components/Content'
import FilterInput from '@components/FilterInput'
import { GenerateLinkIcon } from '@components/GenerateLink'
import { replaceSpecialChars } from '@src/utils/stringSpecialChars'
import { FC, memo, useState } from 'react'
import styles from './CorrespondentBank.module.scss'
import CorrespondentBankNotFound from './CorrespondentBankNotFound'
import Markdown from '@components/Markdown'

interface ICorrespondentBank {
  __component: string
  id: number
  title?: string
  subTitle?: string
  searchPlaceholder: string
  labelLoadMoreButton: string
  numberItemsToShow: number
  bankCorrespondenceNotFound: IBankCorrespondenceNotFound
  items: IITems[]
}
interface IITems {
  id: number
  title: string
  description: string
  subDescription?: string
  link: any
  city: string
}
interface IBankCorrespondenceNotFound {
  description: string
  subDescription: string
  icon: any
}

const CorrespondentBank: FC<ICorrespondentBank> = ({
  title,
  subTitle,
  items,
  searchPlaceholder,
  bankCorrespondenceNotFound,
  labelLoadMoreButton,
  numberItemsToShow,
}) => {
  const [numberOfItems, setNumberOfItems] = useState<number>(numberItemsToShow)
  const [itemsShow, setItemsShow] = useState(items)
  const filterItems = valueSearch => {
    setItemsShow(
      items.filter(item =>
        replaceSpecialChars(item.city ? item.city : '')
          .toUpperCase()
          .includes(valueSearch.toUpperCase())
      )
    )
    setNumberOfItems(itemsShow.length > 0 && valueSearch ? itemsShow.length : numberItemsToShow)
  }
  const RenderButton = ({ label }) => {
    return (
      <Button
        marginTop="20px"
        size="lg"
        backgroundColor="#3D9DEF"
        height="75px"
        color="#FFF"
        variantColor="blue"
        type="button"
        isDisabled={numberOfItems >= itemsShow.length}
        onClick={() => setNumberOfItems(numberOfItems + numberItemsToShow)}
      >
        {label}
      </Button>
    )
  }
  const RenderCardItem: FC<IITems> = item => (
    <div className={styles.card}>
      <div className={styles.title}>{item.title}</div>
      <div className={styles.description}>
        <Markdown>{item.description}</Markdown>
      </div>
      <div className={styles.description}>{item.subDescription}</div>
      <GenerateLinkIcon {...item.link} />
    </div>
  )
  const renderCard: FC<IITems> = item => (
    <div key={item.id} className={styles.cardFrame}>
      <div className={styles.cardColumn}>
        <div className={styles.icon}>
          <img src="/check.svg" />
        </div>
        <RenderCardItem {...item} />
      </div>
    </div>
  )

  return (
    <Content className={styles.component}>
      <div className={styles.componentHearder}>
        <div className={styles.componentTitleSubSubTitle}>
          <div>
            <div className={styles.componentTitle}>{title}</div>
          </div>
          <div>
            <div className={styles.componentSubTitle}>{subTitle}</div>
          </div>
        </div>
        <div className={styles.search}>
          <FilterInput placeholder={searchPlaceholder} icon={'/search.svg'} setSearchValue={filterItems} />
        </div>
      </div>
      {itemsShow.length > 0 ? (
        <>
          <div className={styles.componentFrame}>{itemsShow.slice(0, numberOfItems).map(renderCard)}</div>
          <div className={styles.button}>
            <RenderButton label={labelLoadMoreButton} />
          </div>
        </>
      ) : (
        <div>
          <CorrespondentBankNotFound {...bankCorrespondenceNotFound}></CorrespondentBankNotFound>
        </div>
      )}
    </Content>
  )
}

export default memo(CorrespondentBank)
