import * as Sentry from '@sentry/nextjs'
export const configSentry = {
  environment: process.env.NODE_ENV,

  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,
  enabled: true,
}
Sentry.init(configSentry)
Sentry.setTag('location', 'client')
