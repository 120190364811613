import { Flex } from '@chakra-ui/core'
import PopoverList from '@components/PopoverList'
import { IBar } from '@components/Sections/InvestmentSimulator/InvestimentSimulator.interface'
import useWindowSize from '@src/hooks/useWindowSize'
import { colorStrapi } from '@src/utils/strapi'
import { FC, useCallback, useMemo } from 'react'
import styles from './InvestimentChart.module.scss'

export const formatValueMonetary = (value: number | undefined) =>
  value?.toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) as string

export interface IChart {
  info: string
  bars: IBar[]
}

const MAX_PERCENT_HEIGHT_BAR = 0.8
const MAX_PERCENT_HEIGHT_LINE = 0.8
const MAX_PERCENT_HEIGHT_FIRST_LINE = 0.85
const FACTOR_MIN_VALUE_PERCENT = 0.8

const PERCENT_BAR = (_value: string) => `${parseInt(_value) * MAX_PERCENT_HEIGHT_BAR}%`
const PERCENT_LINE = (baseValue: string, value, bars) => {
  return `${parseInt(baseValue) * (value === bars[0].value ? MAX_PERCENT_HEIGHT_FIRST_LINE : MAX_PERCENT_HEIGHT_LINE)}%`
}

const callbackValue = (value: number): string => {
  if (value === 0) return 'Não possui'
  if (value === 1) return '30 dias'
  return `${value} meses`
}

export const LABELS = [
  {
    key: 'gracePeriod',
    label: 'Carência',
    type: 'string',
    callbackValue,
  },
  { key: 'totalApplied', label: 'Valor Bruto Aplicado', type: 'money' },
  { key: 'incomeTax', label: 'IR sobre o Rendimento', type: 'money' },
  { key: 'netAccumulatedValue', label: 'Valor Líq. da Aplicação (Acumulado)', type: 'money' },
  { key: 'netInvestimentValue', label: 'Valor Rendimento Líquido \n (Acumulado)', type: 'money' },
  { key: 'netInvestmentValuePerMonth', label: 'Valor Rendimento Líquido (mês)', type: 'money' },
  { key: 'netRentabilityMonth', label: 'Rentabilidade Líquida (mês)', type: 'percentage' },
  { key: 'netRentabilityYear', label: 'Rentabilidade Líquida (ano)', type: 'percentage' },
  { key: 'netRentabilityTotal', label: '% rendimento Líquido s/ vlr aplicado no período', type: 'percentage' },
]

const Barline = ({ bottom }) => <Flex as="div" bottom={bottom} className={styles.barLine}></Flex>

const Bar: FC<IBar> = ({ label, value, result, color, bars }) => {
  const calculatePercentProfit = useCallback(
    _value => {
      const minValue = ((bars && bars[0].value) ?? 0) * FACTOR_MIN_VALUE_PERCENT
      const maxValue = (bars && bars[3].value) ?? 0
      const diff = maxValue - minValue
      const percent = ((_value - minValue) / diff) * 100
      return `${(percent > 100 ? 100 : percent).toFixed(2)}%`
    },
    [value, bars]
  )

  const baseValue = useMemo(() => calculatePercentProfit(value), [value, bars])

  const renderBar = props => (
    <Flex
      as="div"
      className={styles.bar}
      height={PERCENT_BAR(baseValue)}
      background={`linear-gradient(180deg, ${colorStrapi(color)} 0%, rgba(61, 157, 239, 0) 100%); border-radius: 8px`}
      {...props}
    />
  )
  const { isMobile } = useWindowSize()
  return (
    <div className={styles.barGroup}>
      <div className={styles.chartLabel}>{label}</div>
      <Barline bottom={PERCENT_LINE(baseValue, value, bars)} />
      {result ? (
        <PopoverList
          data={result}
          header={label}
          labels={LABELS}
          placement={isMobile ? 'top' : 'auto'}
          contentWidth={{ lg: '417px', md: '317px', sm: '317px' }}
        >
          {renderBar({})}
        </PopoverList>
      ) : (
        renderBar({})
      )}
    </div>
  )
}

const BarInfo: FC<{ value: number | undefined }> = ({ value }) => {
  if (!value) return null

  return (
    <div className={styles.chartInfo}>
      <h1 className={styles.chartInfoLabel}>Rendimento</h1>
      <div className={styles.chartInfoValue}>{`R$ ${formatValueMonetary(value)}`}</div>
    </div>
  )
}

const InvestmentCalculation: FC<IBar & { baseRelation: IBar }> = ({ value, label, baseRelation }) => {
  const calculatePercentProfitDiff = (valueBase: number, baseRelationValue: number) => {
    const AGGREGATE_VALUE = 100
    const VALUE_RELATED_TO_BASE = valueBase / baseRelationValue - 1
    const PERCENT_PROFIT = VALUE_RELATED_TO_BASE * 100
    const PERCENT_DIFF = PERCENT_PROFIT + AGGREGATE_VALUE
    return `${PERCENT_DIFF.toFixed(2)}%`
  }

  const profitDiff = useMemo(() => calculatePercentProfitDiff(Number(value), Number(baseRelation?.value)), [
    value,
    baseRelation.value,
  ])

  return label !== baseRelation.label ? (
    <div className={styles.chartInfo}>
      <img src="/chevron.svg" />
      <div className={styles.chartInfoProfit}>{profitDiff}</div>
      <div>de rendimentos em relação a {baseRelation.label}</div>
    </div>
  ) : null
}

export const InvestimentChart: FC<IChart> = chartProps => {
  const barValues = chartProps.bars.map(bar => bar.value)

  const renderBar = (bar: IBar, index: number) => (
    <Bar {...bar} key={bar.id} value={barValues[index]} bars={chartProps.bars} />
  )

  const renderChartInfos = (bar: IBar, index: number) => (
    <div key={bar.id}>
      <BarInfo value={barValues[index]} />

      {Boolean(bar.value) && (
        <InvestmentCalculation
          {...bar}
          baseRelation={{ ...chartProps.bars[0], value: barValues[0] }}
          value={barValues[index]}
        />
      )}
    </div>
  )

  return (
    <>
      <div className={styles.chart}>{chartProps.bars.map(renderBar)}</div>
      <div className={styles.chartInfos}>{chartProps.bars.map(renderChartInfos)}</div>
    </>
  )
}

export default InvestimentChart
