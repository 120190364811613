import Content from '@components/Content'
import Markdown from '@components/Markdown'
import { FC, memo } from 'react'

interface IContent {
  __component: string
  content: string
}

const ContentMarkdown: FC<IContent> = ({ content }) => {
  return (
    <Content>
      <Markdown>{content}</Markdown>
    </Content>
  )
}

export default memo(ContentMarkdown)
