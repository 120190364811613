import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/core'
import ButtonStrapi, { ButtonProps } from '@components/PresentationButton'
import Image from 'next/image'
import { FC } from 'react'
import styles from './ModalDetailed.module.scss'

export interface IModalDetailed {
  icon: any
  title: string
  description: string
  button: ButtonProps
}

export interface IModalDetailedProps {
  isOpen: boolean
  onClose: () => void
}

const ModalDetailed: FC<IModalDetailedProps & IModalDetailed> = ({
  isOpen,
  icon,
  onClose,
  title,
  description,
  button,
}) => {
  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={400}>
        <ModalOverlay />
        <ModalContent borderRadius="12px" padding={{ base: '40px 0', lg: '40px' }}>
          <div className={styles.modalContent}>
            {Boolean(icon) && <Image src={icon.url} width={80} height={80}></Image>}
            <div className={styles.header}>{title}</div>
            <div className={styles.description}>{description}</div>

            <div>
              <ButtonStrapi {...button}></ButtonStrapi>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default ModalDetailed
