import Content from '@components/Content'
import TitleWithIcon from '@components/TitleWithIcon'
import useWindowSize from '../../../hooks/useWindowSize'
import AboutWithIconOverImage from '../AboutWithIconOverImage'
import styles from './AboutBillsExchange.module.scss'

const AboutBillsExchange = ({ image, icon, title, description, investment_type_title, items }) => {
  const { isMobile } = useWindowSize()

  const renderInvestimentType = ({ id, title: itemTitle, description: itemDescription, icon: itemIcon }) => {
    return (
      <div key={id} className={styles.item}>
        <TitleWithIcon title={itemTitle} iconUrl={itemIcon.url} />
        <p className={styles.itemDescription}>{itemDescription}</p>
      </div>
    )
  }

  return (
    <>
      <AboutWithIconOverImage title={title} image={image} icon={icon} description={description} hideImage={isMobile} />
      <Content>
        <div>
          <h1 className={styles.investmentTypeTitle}>{investment_type_title}</h1>
          <div className={styles.investmentTypeContainer}>{items.map(renderInvestimentType)}</div>
        </div>
      </Content>
    </>
  )
}

export default AboutBillsExchange
