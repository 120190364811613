import { iCard } from '@components/CardFrame'
import Content from '@components/Content'
import { IImageProps } from '@components/ImageFrame'
import useWindowSize from '@src/hooks/useWindowSize'
import React, { FC } from 'react'
import styles from './CardGroup.module.scss'

export const CardGroup: FC<any> = ({ children, changeOrderResponsive, changeOrderImageText, onlyOneImage }) => {
  const { isMobile, isTablet } = useWindowSize()
  const style = onlyOneImage
    ? changeOrderImageText
      ? styles.groupOnlyOneImageReverse
      : styles.groupOnlyOneImage
    : changeOrderImageText
    ? styles.groupImageTextReverse
    : styles.group
  const styleMobile = changeOrderResponsive ? styles.groupReverse : styles.group

  return (
    <Content className={isMobile || isTablet ? styleMobile : style}>
      <div className={styles.column1}>
        <RenderOnlyOneChild renderid={isMobile || isTablet ? 0 : changeOrderImageText ? 1 : 0}>
          {children}
        </RenderOnlyOneChild>
      </div>
      <div className={styles.column2}>
        <RenderOnlyOneChild renderid={isMobile || isTablet ? 1 : changeOrderImageText ? 0 : 1}>
          {children}
        </RenderOnlyOneChild>
      </div>
    </Content>
  )
}

interface IRenderOnly {
  renderid: number
  children: FC<iCard> | FC<IImageProps>
}

class RenderOnlyOneChild<T> extends React.Component<T & IRenderOnly> {
  render() {
    const renderId = this.props.renderid
    const children = this.props.children
    return (
      <div>
        {React.Children.map(children, (child, i) => {
          if (i === renderId) return child
        })}
      </div>
    )
  }
}
