import { IPopover, PopoverArrow } from '@chakra-ui/core'
import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import { IInvestment } from '@components/Sections/InvestmentSimulator/InvestimentSimulator.interface'
import { SimulationResult } from '@components/SimulatorCard/SimulatorCard.interface'
import Image from 'next/image'
import { FC, useCallback, useMemo } from 'react'
import styles from './PopoverList.module.scss'

type CallbackValue = (value: number) => string

interface LabelItem {
  key: string
  label: string
  type: string
  callbackValue?: CallbackValue
}

interface LabelItemWithValue extends LabelItem {
  value: string | null
}

interface IProps {
  data: SimulationResult | IInvestment
  labels: LabelItem[]
  header?: string
  contentWidth?: any
}

export const PopoverList: FC<IProps & IPopover> = ({ data, labels, header, children, ...rest }) => {
  const renderValue = useCallback((value, type, callbackValue) => {
    switch (type) {
      case 'percentage':
        return `${Number(value).toLocaleString('pt-br', { maximumFractionDigits: 2 })}%`
      case 'date':
        return new Date(value).toLocaleString('pt-br').split(' ')[0]
      case 'money':
        return Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
      case 'string':
        if (callbackValue) return callbackValue(value)
        return value
    }
  }, [])

  const labelsWithValue = useMemo(() => {
    return labels.map((label: LabelItem) => {
      return {
        ...label,
        value: data[label.key],
      }
    })
  }, [labels, data])

  return (
    <Popover placement="auto" {...rest}>
      <PopoverTrigger>{children}</PopoverTrigger>

      <PopoverContent width={rest?.contentWidth}>
        <PopoverArrow />

        {header && (
          <div className={styles.popoverHeader}>
            <Image src={'/dollar$.svg'} width={20} height={20} />
            <h1>{header}</h1>
          </div>
        )}

        <div className={styles.body}>
          {labelsWithValue
            .filter(item => Boolean(item.value != null))
            .map((item: LabelItemWithValue) => (
              <li key={item.key} className={styles.divline}>
                <h1 className={styles.label}>{item.label}</h1>
                <h2 className={styles.value}>{renderValue(item.value, item.type, item.callbackValue)}</h2>
              </li>
            ))}
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default PopoverList
