import ButtonStrapi from '@components/ButtonStrapi'
import { ILinkProps } from '@components/GenerateLink'
import TextWithLink from '@components/TextWithLink'
import { FC } from 'react'
import styles from './ModalAlert.module.scss'

export interface IModalAlert {
  title: string
  description: string
  button: any
  link: ILinkProps
  onHandleButton: () => void
}

const ModalAlert: FC<IModalAlert> = ({ title, description, button, link, onHandleButton }) => {
  return (
    <>
      <div className={styles.modal}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>
          <TextWithLink content={description} link={link}></TextWithLink>
        </div>
        {button && button.label && (
          <ButtonStrapi
            {...button}
            buttonProps={{ height: '57px', backgroundColor: '#3D9DEF', color: '#FFF', width: '100%' }}
            handleButton={onHandleButton}
          ></ButtonStrapi>
        )}
      </div>
    </>
  )
}

export default ModalAlert
