import { GenerateLink } from '@components/GenerateLink'
import { FC, memo, useMemo } from 'react'
import { IItem, IItems } from './ListFrame.interface'
import styles from './ListFrame.module.scss'

const iconDefault = '/check.svg'

interface IListFrameProps {
  noCentralizeResponsive?: boolean
  detailed?: boolean
}

const RenderLabelItem = props => {
  const getType = ({ file, url }) => {
    if (file) {
      return 'file'
    } else if (url) {
      return 'external'
    }
    return ''
  }

  const type = useMemo(() => getType(props), [props])

  if (type) {
    return (
      <div className={styles.label}>
        <GenerateLink {...props} type={type}>
          <div className={styles.link}>{props.label}</div>
        </GenerateLink>
      </div>
    )
  }

  return <div>{props.label}</div>
}

const RenderItem: FC<IItem & IListFrameProps> = ({
  label,
  icon,
  details,
  id,
  noCentralizeResponsive,
  numberOfItens,
  ...rest
}) => {
  const styleProp =
    numberOfItens && numberOfItens > 8
      ? {
          padding: '10px',
        }
      : undefined

  return (
    <div key={id} className={noCentralizeResponsive ? styles.item2 : styles.item} style={styleProp}>
      <img className={styles.icon} src={icon ? icon?.url : iconDefault} />
      {details ? (
        <div className={styles.labelWithDetails}>
          <RenderLabelItem label={label} {...rest} />
          <div className={styles.details}>{details}</div>
        </div>
      ) : (
        <RenderLabelItem label={label} {...rest} />
      )}
    </div>
  )
}

const ListFrame: FC<IItems & IListFrameProps> = ({ items, detailed, noCentralizeResponsive }) => {
  return (
    <div className={detailed ? styles.listWithDetails : styles.listWithoutDetails}>
      {items.map(props => (
        <RenderItem key={props.id} {...props} {...noCentralizeResponsive} numberOfItens={items.length} />
      ))}
    </div>
  )
}

export default memo(ListFrame)
