import { GenerateLink, ILinkProps } from '@components/GenerateLink'
import { memo } from 'react'

interface ITextWithLink {
  content: string
  link: ILinkProps
  classNameLink?: string
}
const TextWithLink: React.FC<ITextWithLink> = ({ content, link, classNameLink }) => {
  const [preffix, linkText, suffix] = content.split(/\{(.*?)\}/gm)
  return linkText ? (
    <p>
      {preffix}
      <GenerateLink {...link}>
        <span className={classNameLink}>{linkText}</span>
      </GenerateLink>
      {suffix}
    </p>
  ) : (
    <p>{content}</p>
  )
}

export default memo(TextWithLink)
