import { CardFrameCheck } from '@components/CardFrame'
import { CardGroup } from '@components/CardGroup'
import { GenerateLinkIcon, ILinkProps } from '@components/GenerateLink'
import ListFrame from '@components/ListFrame'
import { IPagePresentation } from '@src/interfaces/Home.interface'
import { FC, memo } from 'react'
import ButtonStrapi, { ButtonStrapiProps } from '../../ButtonStrapi'
import { CardFrameButton } from '../../CardFrame'
import ImageFrame from '../../ImageFrame'
import styles from './PagePresentation.module.scss'

const PagePresentation = (pageProps: IPagePresentation) => {
  const onlyOneImage =
    !pageProps.imageGroup?.sideImage1 && !pageProps.imageGroup?.sideImage2 && !pageProps.imageGroup?.sideImage3
  const RenderButton: FC<ButtonStrapiProps> = button =>
    Boolean(button) && button?.label ? <ButtonStrapi {...button} /> : null

  const RenderLink: FC<ILinkProps> = item =>
    item ? (
      <div className={styles.link}>
        <GenerateLinkIcon {...item} />
      </div>
    ) : null

  const RenderSubdescription: FC<{ subdescription: string | null }> = ({ subdescription }) => {
    return subdescription ? <div className={styles.subdescription}>{subdescription}</div> : null
  }

  const renderCardFrameButton: FC<IPagePresentation> = ({
    title,
    subtitle,
    description,
    subdescription,
    button,
    item: itemWitchIcon,
  }) => {
    return (
      <CardFrameButton {...{ title, subtitle, description }}>
        <RenderButton {...{ ...button }} />
        <div className={styles.frameText}>
          <RenderLink {...{ ...itemWitchIcon }} />
          <RenderSubdescription {...{ subdescription }} />
        </div>
      </CardFrameButton>
    )
  }

  const renderCardFrameCheck: FC<IPagePresentation> = ({
    title,
    subtitle,
    description,
    listItem,
    button,
    item: itemWitchIcon,
  }) => {
    return (
      <CardFrameCheck {...{ title, subtitle, description }}>
        <ListFrame items={listItem} />
        <RenderButton {...{ ...button }} />
        <RenderLink {...{ ...itemWitchIcon }} />
      </CardFrameCheck>
    )
  }

  const renderFrame: FC<IPagePresentation> = props => {
    if (props.typeCard === 'text') return renderCardFrameButton(props)
    if (props.typeCard === 'list' && props.listItem) return renderCardFrameCheck(props)
    return null
  }
  return (
    <CardGroup {...pageProps} onlyOneImage={onlyOneImage}>
      {renderFrame(pageProps)}
      {pageProps.imageGroup && <ImageFrame {...pageProps.imageGroup} />}
    </CardGroup>
  )
}

export default memo(PagePresentation)
