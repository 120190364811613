import { Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/core'
import { FC } from 'react'
import useWindowSize from '../../hooks/useWindowSize'
import styles from './ModalList.module.scss'

export interface IModalListProps {
  isOpen: boolean
  onClose: () => void
  modalList: IModalList
}

export interface IModalList {
  id: number
  title: string
  items: string
}

export const ModalList: FC<IModalListProps> = ({ isOpen, onClose, modalList }) => {
  const { isDesktop } = useWindowSize()
  const MAX_ITEMS_COLUMN = 40

  const removePrefixList = (line: string) => {
    if (line.charAt(0) === '-') return line.replace(/- /, '')
    return ''
  }

  const list = modalList.items.split(/\r?\n/)
  const listClean = list.map(removePrefixList)
  if (listClean.length <= 0) return null

  const lists =
    listClean.length > MAX_ITEMS_COLUMN && isDesktop
      ? [listClean.slice(0, (listClean.length + 1) / 2), listClean.slice((listClean.length + 1) / 2)]
      : [listClean]

  const RenderItem = (lines: string) => <li key={lines}>{lines}</li>
  const RenderList = (table: Array<string>, index: number) => (
    <td key={index}>
      <ul>{table.map(RenderItem)}</ul>
    </td>
  )
  const TableList = (tableColumn: Array<Array<string>>) => {
    return <tr>{tableColumn.map(RenderList)}</tr>
  }

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size={'fit-content'}>
        <ModalOverlay />
        <ModalContent borderRadius="12px" padding={{ base: '40px 0', lg: '40px' }}>
          <Flex as="div" alignSelf="flex-end">
            <img src="/close.svg" onClick={onClose} />
          </Flex>
          <ModalHeader fontSize={20}>
            <div className={styles.header}>{modalList.title}</div>
          </ModalHeader>
          <ModalBody>
            <div className={styles.modalList}>{TableList(lists)}</div>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}
