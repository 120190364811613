import { Tab, TabList, TabPanels, Tabs } from '@chakra-ui/core'
import { CardFrameCheck } from '@components/CardFrame'
import { CardGroup } from '@components/CardGroup'
import Content from '@components/Content'
import ImageFrame from '@components/ImageFrame'
import ListFrame from '@components/ListFrame'
import { IPagePresentationWithTabs } from '@src/interfaces/Home.interface'
import { FC, memo, useState } from 'react'
import useWindowSize from '../../../hooks/useWindowSize'
import styles from './PagePresentationWithTabs.module.scss'

const PagePresentationWithTabs: FC<IPagePresentationWithTabs> = pageProps => {
  const tabContent = (index: number) => {
    return pageProps.items[index]
  }
  const [tabIndex, setTabIndex] = useState(0)
  const [tabProps, setTabProps] = useState(tabContent(tabIndex))

  const handleOnChange = (index: number) => {
    setTabIndex(index)
    setTabProps(tabContent(index))
  }

  const { isDesktop } = useWindowSize()

  const RenderTabList: FC<any> = ({ items }) => {
    return (
      <TabList alignItems="flex-end" className={styles.tabList}>
        {items.map(({ id, title: tabTitle }) => (
          <Tab key={id}>{tabTitle}</Tab>
        ))}
      </TabList>
    )
  }
  const RenderDesktop: FC<IPagePresentationWithTabs> = ({ items, title, subtitle }) => {
    const { listItem, imageGroup } = tabProps

    return (
      <CardGroup changeOrderResponsive>
        <CardFrameCheck {...{ title, subtitle }}>
          <ListFrame items={listItem} detailed />
        </CardFrameCheck>

        <Tabs index={tabIndex} onChange={handleOnChange} align="center" isFitted borderColor="">
          <RenderTabList items={items} />
          <TabPanels paddingTop="90px">{imageGroup && <ImageFrame {...imageGroup} />}</TabPanels>
        </Tabs>
      </CardGroup>
    )
  }

  const RenderMobile: FC<IPagePresentationWithTabs> = ({ items, title, subtitle }) => {
    const { listItem, imageGroup } = tabProps

    return (
      <Content>
        <CardFrameCheck {...{ title, subtitle }}>
          <Tabs index={tabIndex} onChange={handleOnChange} align="center" borderColor="">
            <RenderTabList items={items} />
            <TabPanels>
              {imageGroup && <ImageFrame {...imageGroup} />}
              <ListFrame items={listItem} detailed />
            </TabPanels>
          </Tabs>
        </CardFrameCheck>
      </Content>
    )
  }

  return isDesktop ? <RenderDesktop {...pageProps} /> : <RenderMobile {...pageProps} />
}

export default memo(PagePresentationWithTabs)
