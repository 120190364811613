import parse from 'html-react-parser'
export const colorStrapi = (color?: string, defaultColor?: string) => {
  return color && JSON.parse(color).hex && JSON.parse(color).hex !== '#000000ff'
    ? JSON.parse(color).hex
    : defaultColor || '#1f81d1'
}

const wordsToChange: {
  [s: string]: string
} = {
  '{year}': new Date().getFullYear().toString(),
  '{day}': new Date().getDate().toString(),
  '{month}': (new Date().getMonth() + 1).toString(),
}

export const strapiParseText = (text: string) => {
  Object.entries(wordsToChange).forEach((key: any) => {
    text = text.split(key[0]).join(key[1])
  })
  return parse(text)
}
