import { useDisclosure } from '@chakra-ui/hooks'
import Alert, { IAlert } from '@components/Alert'
import { CardWithIconDetailed } from '@components/CardWithIconDetailed'
import { CardWithIconLink, ICardWithIconLink } from '@components/CardWithIconLink'
import Content from '@components/Content'
import ModalDetailed, { IModalDetailed } from '@components/ModalDetailed'
import StrapiForm from '@components/StrapiForm'
import { IStrapiForm } from '@components/StrapiForm/StrapiForm.interface'
import { ITextWithIcon, TextWithIcon } from '@components/TextWithIcon'
import * as Sentry from '@sentry/nextjs'
import { api } from '@src/service/api'
import { FC, memo, useCallback, useState } from 'react'
import { UseFormMethods } from 'react-hook-form'
import styles from './ContactUs.module.scss'

interface ITextWithTitle {
  title: string
  description: string
}

export interface IContactUs {
  title: string
  primaryDescription: ITextWithTitle
  secondaryDescription: ITextWithTitle
  subdescription: string
  cards: any
  infoText: ITextWithIcon
  infoTextLink: ITextWithIcon
  form: IStrapiForm
  formTitle: string
  formDescription: string
  cardsLinkIcon: ICardWithIconLink[]
  formSuccessModal: IModalDetailed
}

const ContactUs: FC<IContactUs> = ({
  title,
  primaryDescription,
  secondaryDescription,
  subdescription,
  cards,
  infoText,
  infoTextLink,
  form: strapiForm,
  formTitle,
  formDescription,
  cardsLinkIcon,
  formSuccessModal,
}) => {
  const [alert, setAlert] = useState<IAlert>({})
  const [loading, setLoading] = useState<boolean>(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const TextWithTitle = ({ title: textTitle, description }: ITextWithTitle) => {
    description = description.replace(/(?:\r\n|\r|\n)/g, ' <br/> ')
    return (
      <div className={styles.text}>
        <div className={styles.textTitle}>{textTitle}</div>
        <div className={styles.textDescription} dangerouslySetInnerHTML={{ __html: description }}></div>
      </div>
    )
  }

  const onSubmit = useCallback(
    async (formData: any, form: UseFormMethods) => {
      const submitData = strapiForm.inputs.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.name]: formData[curr.name],
        }),
        {}
      )

      try {
        setLoading(true)
        await api.post(`/pages/form/${strapiForm.id}`, submitData)
        form.reset()
        setAlert({})
        onOpen()
      } catch (ex) {
        Sentry.captureException(ex)
        setAlert({
          status: 'error',
          message: strapiForm.error_message,
        })
      } finally {
        setLoading(false)
      }
    },
    [strapiForm]
  )

  return (
    <div className={styles.container}>
      <Content className={styles.content}>
        <div className={styles.contentContainer}>
          <div>
            <p className={styles.title}>{title}</p>
            <div className={styles.cards}>
              {cards.slice(0, 2).map(card => (
                <CardWithIconDetailed key={card.id} {...card}></CardWithIconDetailed>
              ))}
            </div>

            <TextWithTitle {...primaryDescription} />
            <TextWithTitle {...secondaryDescription} />

            <div className={styles.cards}>
              {cards.slice(-2).map(card => (
                <CardWithIconDetailed key={card.id} {...card}></CardWithIconDetailed>
              ))}
            </div>

            <div className={styles.subDescription}>{subdescription}</div>

            {infoTextLink && <TextWithIcon key={infoTextLink.id} {...infoTextLink}></TextWithIcon>}
            {infoText && <TextWithIcon key={infoText.id} {...infoText}></TextWithIcon>}
          </div>

          <div>
            <div className={styles.form}>
              <div className={styles.formContent}>
                {alert.status ? <Alert {...alert}></Alert> : null}
                <h1>{formTitle}</h1>
                <h2>{formDescription}</h2>

                <StrapiForm
                  inputs={strapiForm.inputs}
                  buttonFullWidth
                  submitText={strapiForm.submit_text}
                  loading={loading}
                  onSubmit={onSubmit}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.cardsLinkIcon}>
          {Boolean(cardsLinkIcon?.length) &&
            cardsLinkIcon.map(card => <CardWithIconLink key={card.id} {...card}></CardWithIconLink>)}
        </div>

        <ModalDetailed {...{ isOpen, onClose, ...formSuccessModal }}></ModalDetailed>
      </Content>
      <div className={styles.formBackground}>
        <img className={styles.formDotsBottom} src="/dots-contact-us.svg" />
      </div>
    </div>
  )
}

export default memo(ContactUs)
