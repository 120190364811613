import Image from 'next/image'
import styles from './CardWithIconDetailed.module.scss'

interface ICardWithIconDetailed {
  title: string
  description: string
  icon: any
}

export const CardWithIconDetailed: React.FC<ICardWithIconDetailed> = ({ title, description, icon }) => {
  return (
    <div className={styles.card}>
      <div className={styles.iconContainer}>
        {Boolean(icon?.url) && <Image src={icon.url} width={25} height={25} />}
      </div>
      <div className={styles.cardContent}>
        <p className={styles.title}>{title}</p>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  )
}
