import { FormControl, FormControlProps, Input, InputProps } from '@chakra-ui/react'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import FormError from '../FormError'
import FormLabel from '../FormLabel'
import styles from './TextInput.module.scss'

export interface TextInputProps extends InputProps {
  label: string
  name: string
  required?: boolean
  disabled?: boolean
  defaultValue: string
  type?: string
  accept?: string
  multiple?: boolean
  allowClear?: boolean
  disableErrorMessage?: boolean
  onInput?: any
}

const TextInput: React.FC<TextInputProps & FormControlProps> = ({
  label,
  defaultValue,
  name,
  required,
  disabled,
  disableErrorMessage,
  ...rest
}) => {
  const { register, errors } = useFormContext()

  return (
    <FormControl {...rest}>
      <FormLabel required={required}>{label}</FormLabel>
      <div className={styles.inputContainer}>
        <Input
          name={name}
          ref={register({
            required,
          })}
          defaultValue={defaultValue}
          data-testid={name}
          isInvalid={errors[name]}
          isDisabled={disabled}
          {...rest}
        />
      </div>
      {!disableErrorMessage && <FormError>{errors[name]?.message}</FormError>}
    </FormControl>
  )
}

export default TextInput
