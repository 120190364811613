import { Input, InputGroup, InputRightElement } from '@chakra-ui/core'
import { IconButton } from '@chakra-ui/react'
import { replaceSpecialChars } from '@src/utils/stringSpecialChars'
import { FC, useState } from 'react'

export interface IFilterInput {
  placeholder: string
  icon: any
  setSearchValue: any
}

const FilterInput: FC<IFilterInput> = ({ placeholder, icon, setSearchValue }) => {
  const [textValue, setTextValue] = useState<string>('')
  const handleClick = () => setSearchValue(textValue)
  const handleInputChange = e => {
    const inputValue = e.target.value
    setTextValue(replaceSpecialChars(inputValue))
    if (inputValue.length === 0) {
      setSearchValue('')
    }
  }
  return (
    <InputGroup size="lg">
      <Input pr="2.8rem" width={'100%'} placeholder={placeholder} onChange={handleInputChange} />
      <InputRightElement padding={'0px 25px'}>
        <IconButton
          size={'md'}
          aria-label="#1F81D1"
          backgroundColor="#1F81D1"
          icon={<img src={icon} width="20" height="20" />}
          onClick={handleClick}
        ></IconButton>
      </InputRightElement>
    </InputGroup>
  )
}
export default FilterInput
