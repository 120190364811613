import CookiesConsent from '@components/CookiesConsent'
import { GenerateLink } from '@components/GenerateLink'
import IconListWithLink from '@components/IconListWithLink'
import { strapiParseText } from '@src/utils/strapi'
import styles from './Footer.module.scss'

type PropsFooterList = {
  footerList: any
  cookiesData?: any
  privacyPolicyData?: any
  savaDatePrivacyPolicy?: () => void
}

const ItemsWithoutLink = ({ label, description, subDescription }) => {
  return (
    <div>
      <div className={styles.link}>{label}</div>
      {description && <div className={styles.description}>{description}</div>}
      {subDescription && <div className={styles.subDescription}>{subDescription}</div>}
    </div>
  )
}

export const Footer: React.FC<PropsFooterList> = ({
  footerList,
  cookiesData,
  privacyPolicyData,
  savaDatePrivacyPolicy,
}) => {
  const { iconListWithLink } = footerList?.business

  return (
    <div>
      <div className={styles.footer}>
        <div className={styles.content}>
          {footerList.items.map(item => (
            <div className={styles.column} key={`${item.__component}-${item.id}`}>
              {Boolean(item.title) && <div className={styles.title}>{item.title}</div>}
              <ul className={styles.listFooter}>
                {item.items.map(subItem => (
                  <li className={styles.listItem} key={`${subItem.label} ${subItem.id}`}>
                    {subItem?.url || subItem?.page ? <GenerateLink {...subItem} /> : <ItemsWithoutLink {...subItem} />}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className={styles.contentLine}></div>
        <div className={styles.contentBusiness}>
          <div className={styles.contentColumn}>
            <div className={styles.footerText}>
              <div className={styles.businessName}>{footerList.business.name}</div>
            </div>
            <div className={styles.footerText}>
              <div>{footerList.business.address}</div>
            </div>
          </div>
          {iconListWithLink && iconListWithLink.map(IconListWithLink)}
        </div>
      </div>

      <div className={styles.footerCopyright}>
        <div className={styles.contentCopyright}>
          <div className={styles.footerText}>{strapiParseText(footerList.copyright.copyright)}</div>
          {cookiesData && (
            <CookiesConsent
              {...cookiesData}
              privacyPolicyData={privacyPolicyData}
              savaDatePrivacyPolicy={savaDatePrivacyPolicy}
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default Footer
